import React, { useContext, useEffect, useState } from "react"

import { OfferCardProps } from "./OfferCard.d"
import tw from "twin.macro"
import { Card } from "../../atoms/Card"
import { Badge } from "../../atoms/Badge"
import Icon from "../../atoms/Icon"
import { Link } from "../../atoms/Link"
import LeaseCard from "./LeaseCard"
import FinanceCard from "./FinanceCard"
import CashCard from "./CashCard"
import { TWColor } from "../../../global"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import { reformattedOfferType, toUsd } from "../../../helpers"
import { LanguageContext } from "../../../contexts/Language"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import {
  addFavoritedOffer,
  removeFavoritedOffer,
  setFavoriteMessage,
} from "../../../contexts/Favorites/actions"
import useVehicleData from "../../../hooks/Tealium/useVehicleData"
import LeaseCashCard from "./LeaseCashCard"
import FinanceCashCard from "./FinanceCashCard"

/**
 * @author Tyler
 * @summary - Card with conditional rendering based on card label
 *
 * @component
 * @param {string} cardLabel - Label at top of card
 * @param {string} type - Type of card
 * @param {number} duration - Time in months
 * @param {string} startDate - Date the offer beings
 * @param {string} endDate - Date the offer expires
 * @param {string} expIn - Amount of days until offer expires
 * @param {boolean} isExpiringSoon - Will expire within 7 days
 * @param {string} bullet - Offer description
 * @param {number} cashAmount - The amount in cash the offer is for
 * @param {number} dueAtSigningAmount - The amount in cash due at signing
 * @param {any[]} termRates - Options for APR/Finance offers
 * @param {number} series.currentYear - Year of model the offer is for
 * @param {string} series.name - Name of model the offer is for
 * @param {string} _id - Used for the card's key
 * @returns <OfferCard />
 */

const OfferCard: React.FC<OfferCardProps> = ({
  offer,
  cardType,
  cardLabel,
  analyticsId,
  ...remainingProps
}) => {
  const {
    cardTitle,
    type,
    duration,
    monthlyPayment,
    dueAtSigningAmount,
    endDate,
    isExpiringSoon,
    cashAmount,
    termRates,
    series,
    _id,
    year,
    extraCashAmount,
    extraCashLabel,
  } = offer
  const { name } = series || {}
  const { dealer } = useContext(DealerDetailsPageContext)
  const [vehicleData] = useVehicleData()
  const { _, language } = useContext(LanguageContext)

  /* Sets Badge color based on card label */
  let badgeColor: TWColor
  switch (cardType) {
    case "APR":
      badgeColor = "green"
      break
    case "Lease":
      badgeColor = "blue"
      break
    case "Cash":
      badgeColor = "red-400"
      break
    case "Lease Cash":
      badgeColor = "blue"
      break
    case "Finance Cash":
      badgeColor = "green"
      break
    default:
      badgeColor = "red-400"
      break
  }

  // Concatenate "Rebate" to end of type for Cash offers
  let formattedType: string

  // Set aria label based on cardType
  let ariaCardLabel: string

  switch (cardType) {
    case "APR":
      formattedType = `${
        language === "en" ? year + " " + name : name + " " + year
      }`
      const sortedRates = termRates?.sort(
        (a: { rate: number }, b: { rate: number }) => a.rate - b.rate,
      )
      const lowestRates = sortedRates?.filter(
        offer => offer.rate == sortedRates[0].rate,
      )
      const sortedTermRate = lowestRates?.sort(
        (a: { term: number }, b: { term: number }) => b.term - a.term,
      )[0]

      ariaCardLabel = `${formattedType}, ${sortedTermRate?.rate}% ${type} for ${sortedTermRate?.term} months`
      break
    case "Lease":
      formattedType = `${
        language === "en" ? year + " " + name : name + " " + year
      }`
      ariaCardLabel = `Lease a new ${formattedType}, $${monthlyPayment} for ${duration} months`
      break
    case "Cash":
      formattedType = `${
        language === "en" ? year + " " + name : name + " " + year
      }`
      ariaCardLabel = `$${cashAmount} ${formattedType}`
      break
    case "Lease Cash":
      formattedType = `${
        language === "en" ? year + " " + name : name + " " + year
      }`
      ariaCardLabel = `$${cashAmount} ${formattedType}`
      break
    case "Finance Cash":
      formattedType = `${
        language === "en" ? year + " " + name : name + " " + year
      }`
      ariaCardLabel = `$${cashAmount} ${formattedType}`
      break
    default:
      formattedType = type
      break
  }
  // Get a re-formatted offer type string that is expected by Tealium
  let offerType: string = reformattedOfferType(type)

  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData, tealPageData } = useTealiumContext()
  const [{ favoritedOffers }, dispatch] = useContext(FavoritesContext)
  const [isFavorited, setIsFavorited] = useState(false)

  useEffect(() => {
    if (!favoritedOffers || !Array.isArray(favoritedOffers)) return

    setIsFavorited(favoritedOffers.includes(_id))
  }, [favoritedOffers])
  return (
    <div
      css={[
        tw`relative w-72 mx-auto`,
        tw`md:(w-72)`,
        tw`lg:(w-[16rem])`,
        tw`xl:(w-72)`,
      ]}
    >
      <button
        onClick={() => {
          isFavorited
            ? dispatch(removeFavoritedOffer(_id))
            : (dispatch(addFavoritedOffer(_id)),
              dispatch(setFavoriteMessage("offers")))
        }}
        css={[
          tw`absolute top-8 right-4 xl:right-4 z-10 flex items-center border border-transparent p-1 rounded-full hover:bg-gray-100`,
          tw`focus-visible:outline-gray-50`,
        ]}
        aria-label={
          isFavorited
            ? `Unfavorite ${cardTitle} ${cardType} Offer`
            : `Favorite ${cardTitle} ${cardType} Offer`
        }
      >
        <Icon.Heart
          color={isFavorited ? "red-400" : "gray-900"}
          css={[tw`w-6 mt-0.5 ml-auto`]}
          filled={isFavorited}
        />
      </button>
      <Link
        className="group"
        css={[tw`outline-none pt-0 pb-6 focus-visible:outline-none`]}
        to={language === "es" ? `/es/offers/${_id}` : `/offers/${_id}`}
        forceExternal={true} // Needed for expired offer redirect
        target="_self" // Needed for expired offer redirect
        onClick={() => {
          /* 
        Value of offer_type
        Format: PageType | PageName | Offer Name - Title | Offer Name - Detail 
        
        NOTE: The above format is slightly different than that used on Explore site.  
        22Squared has instructed Alloy to use the Explore site format in this case.

        Analytics ID:
        analytics-id HTML property will only be populated by React if analytics id is provided where OfferCard is called.
         */

          // Conditionally add values if they are available
          const tealOptionalValues: {
            dealer_name?: string
            dealer_code?: string
            vehicle_model_truncated?: string
            vehicle_model?: string
            vehicle_year?: string | number
          } = {
            ...(dealer?.Name && { dealer_name: dealer.Name }),
            ...(dealer?.DealerCode && { dealer_code: dealer.DealerCode }),
            ...vehicleData,
          }

          trackTealEvent({
            ...tealOptionalValues,
            offer_type: `${tealPageData.page_type} | ${tealPageData.page_name} | ${offerType} | ${cardTitle}`,
            tealium_event: "view_offer",
          })
        }}
        analytics-id={analyticsId || null}
        aria-label={ariaCardLabel}
      >
        <Card
          shadowLevel={2}
          css={[
            tw`relative w-[16rem] bg-white mt-3 overflow-visible group-hover:(shadow-4 ease-in-out transition-all duration-150) group-focus-visible:(shadow-4 outline-gray-50)`,
            tw`md:(w-72)`,
            tw`lg:(w-[16rem])`,
            tw`xl:(w-72)`,
          ]}
          {...remainingProps}
        >
          {/* BADGE AND HEART ICON */}
          <Badge
            color={badgeColor}
            css={[tw`absolute -top-3 left-0 right-0 mx-auto max-w-min`]}
          >
            {/* Change "APR" to "Finance" */}
            {cardType === "APR" ? _("Finance") : cardLabel}
          </Badge>
          {/* OFFER CARD CONTAINER */}
          <article
            css={[tw`flex flex-col gap-2 justify-between pb-5 w-full h-60`]}
          >
            {/* TYPE */}
            {formattedType && (
              <div
                css={[
                  tw`text-center flex items-center justify-center font-semibold mx-4 min-h-[70px] !leading-[1.7rem]`,
                  formattedType.split("").length <= 20
                    ? tw`text-xl`
                    : tw`text-xl`,
                  extraCashAmount && tw`min-h-[60px]`,
                ]}
              >
                <div>{formattedType}</div>
              </div>
            )}

            {/* INNER CONTENT - LEASE */}
            {cardType === "Lease" && (
              <LeaseCard
                monthlyPayment={monthlyPayment}
                dueAtSigningAmount={dueAtSigningAmount}
                duration={duration}
              />
            )}

            {/* INNER CONTENT - APR(RENAMED TO FINANCE) */}
            {cardType === "APR" && <FinanceCard termRates={termRates} />}

            {/* INNER CONTENT - CASH */}
            {cardType === "Cash" && (
              <CashCard cashAmount={cashAmount} type={type} />
            )}

            {/* INNER CONTENT - Lease Cash */}
            {cardType === "Lease Cash" && (
              <LeaseCashCard cashAmount={cashAmount} type={formattedType} />
            )}

            {/* INNER CONTENT - Finance Cash */}
            {cardType === "Finance Cash" && (
              <FinanceCashCard cashAmount={cashAmount} type={formattedType} />
            )}
            {/* EXTRA CASH AMOUNT */}
            {extraCashAmount ? (
              <p
                css={[
                  tw`block w-full font-semibold uppercase text-center text-sm`,
                ]}
              >
                {"+ $"}
                {toUsd(extraCashAmount)} {_(extraCashLabel)}
              </p>
            ) : null}
            <div>
              {/* EXPIRATION DATE */}
              {endDate && (
                <div css={[tw`flex justify-center text-xs uppercase`]}>
                  {/* If isExpiringSoon is truthy, display red text and warning icon */}
                  <span
                    css={[
                      isExpiringSoon ? tw`text-red-400` : tw`text-gray-700`,
                    ]}
                  >
                    {_("EXP")} {endDate}
                  </span>
                  {isExpiringSoon && (
                    <Icon.Warning color="red-400" css={[tw`w-4 ml-1`]} />
                  )}
                </div>
              )}

              {/* VIEW DETAILS */}
              <div
                css={[
                  tw`flex items-center justify-center text-xs text-center font-semibold mt-3 pb-3`,
                ]}
                className="optimizelyTest10SecondaryMetric"
              >
                <span>{_("View offer details")}</span>
                <Icon.Chevron
                  color="red-400"
                  direction="right"
                  css={[tw`h-3 inline ml-2`]}
                />
              </div>
            </div>
          </article>
        </Card>
      </Link>
    </div>
  )
}

export default OfferCard
